// src/PortfolioPage.js

import React from 'react';

function PortfolioPage() {
  return (
    <div>
      <h1>Portfolio Page</h1>
      <p>Here are some of my projects.</p>
    </div>
  );
}

export default PortfolioPage;

